import { IFlex } from "@types";
import { flexGenerator } from "@fns";
import { createDataAttr } from "@utils";

type FlexMessageProps = {
  message: IFlex;
};

export const FlexMessage = ({ message }: FlexMessageProps) => {
  const result = flexGenerator(message.contents ? message.contents : "");
  return (
    <div
      {...createDataAttr("flex-message")}
      dangerouslySetInnerHTML={{ __html: result }}
    />
  );
};
