import { MessageItem } from "./MessageItem";
import { useMessageBox } from "./useMessageBox";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteScroll from "react-infinite-scroller";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { EMessageType } from "@types";
import { isEmpty } from "lodash";
import QuickReply from "@shares/components/QuickReply";

export const MessageBox = () => {
  const {
    wrapRef,
    messages,
    isFirstFetching,
    isLoadingMore,
    isFetchFailed,
    hasMore,
    onLoadMoreMessages,
    onSendAction,
  } = useMessageBox();

  const _renderQuickReply = () => {
    const lastMsg = messages[messages.length - 1];
    if (!lastMsg) return null;
    if (lastMsg.type === EMessageType.ATTACHMENT) {
      return null;
    }
    const hasQuickReply = !isEmpty(lastMsg.quickReply);
    if (!hasQuickReply) return null;
    return (
      <div>
        <QuickReply
          quickReply={lastMsg.quickReply}
          onSendAction={onSendAction}
        />
      </div>
    );
  };

  return (
    <main className="relative flex-1 bg-[#eeeded]">
      <div className="h-full">
        <AutoSizer>
          {({ height, width }: { height: number; width: number }) => {
            return (
              <div
                ref={wrapRef}
                style={{
                  height: `${height}px`,
                  width: `${width}px`,
                }}
                className="flex flex-col overflow-auto overflow-x-hidden"
              >
                <InfiniteScroll
                  className="flex-1"
                  initialLoad={false}
                  loadMore={() => {
                    if (!isFirstFetching && !isLoadingMore && hasMore) {
                      onLoadMoreMessages();
                    }
                  }}
                  hasMore={!isFetchFailed && hasMore}
                  isReverse
                  threshold={300}
                  loader={
                    isFetchFailed ? (
                      <></>
                    ) : (
                      <div className="flex justify-center text-[20px]">
                        <FontAwesomeIcon icon={faSpinner} spin />
                      </div>
                    )
                  }
                  useWindow={false}
                  getScrollParent={() => wrapRef.current}
                >
                  {messages.map((message) => {
                    return (
                      <MessageItem
                        key={message.id}
                        message={message}
                        onSendAction={onSendAction}
                      />
                    );
                  })}
                </InfiniteScroll>
                <div className="">{_renderQuickReply()}</div>
              </div>
            );
          }}
        </AutoSizer>
      </div>
    </main>
  );
};
