/* eslint-disable no-undef */
/* eslint-disable no-prototype-builtins */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/* @ts-ignore */
export const flexGenerator = (json) => {
  if (!json) return false;
  let carousel = carousel_struc();
  let result = "";

  if (json["type"] === "flex") {
    json = json["contents"];
    if (json["type"] === "flex") {
      json = json["contents"];
      if (json["type"] === "bubble") {
        result = bubble_object(json);
        carousel = carousel.replace("<!-- inner -->", result);
      } else if (json["type"] === "carousel") {
        json["contents"].forEach((obj: any, index: number) => {
          let result = bubble_object(obj);
          result = result.replace("<!-- content -->", "");
          result = result.replace("<!-- inner -->", "");
          carousel = carousel.replace(
            "<!-- inner -->",
            result + "<!-- inner -->"
          );
        });
      }
    }
    return `<html>
      <head>
      <meta charset="UTF-8" />
      <meta
        name="viewport"
        content="width=device-width, user-scalable=no, initial-scale=1, maximum-scale=1"
      />
      <meta name="format-detection" content="telephone=no" />
    
      </head>
      <body style="margin: 0">
        ${carousel}
      </body>
    </html>`;
  } else if (json["type"] === "carousel") {
    json = json["contents"];

    json.forEach((obj: any, index: number) => {
      let result = bubble_object(obj);
      result = result.replace("<!-- content -->", "");
      result = result.replace("<!-- inner -->", "");
      carousel = carousel.replace("<!-- inner -->", result + "<!-- inner -->");
    });
    return `<html>
      <head>
      <meta charset="UTF-8" />
      <meta
        name="viewport"
        content="width=device-width, user-scalable=no, initial-scale=1, maximum-scale=1"
      />
      <meta name="format-detection" content="telephone=no" />
      </head>
      <body style="margin: 0">
        ${carousel}
      </body>
    </html>`;
  }
  if (json["type"] === "bubble") {
    let result = bubble_object(json);
    result = result.replace("<!-- content -->", "");
    result = result.replace("<!-- inner -->", "");
    carousel = carousel.replace("<!-- inner -->", result + "<!-- inner -->");
    return `<html>
      <head>
      <meta charset="UTF-8" />
      <meta
        name="viewport"
        content="width=device-width, user-scalable=no, initial-scale=1, maximum-scale=1"
      />
      <meta name="format-detection" content="telephone=no" />
      </head>
      <body style="margin: 0">
        ${carousel}
      </body>
    </html>`;
  }

  return false;
};

function bubble_object(json: any) {
  let { hero, header, body, footer } = json;
  let hero_object = hero_struc();
  let header_object = header_struc();
  let body_object = body_struc(json);
  let footer_object = footer_struc();
  let bubble = bubble_struc(json);

  let box = "";
  for (let key in hero) {
    if (hero.hasOwnProperty(key)) {
      if (key === "type" && hero[key] === "box") {
        box = box_object(hero);
        let box_inner = box_recursive(box, hero["contents"], hero["layout"]);
        box = box_inner;
        break;
      } else {
        //@ts-ignore
        box = convert_object(hero);
      }
    }
  }

  hero_object = hero_object.replace("<!-- inner -->", box);

  box = "";
  for (let key in header) {
    if (header.hasOwnProperty(key)) {
      if (key === "type" && header[key] === "box") {
        box = box_object(header);
        let box_inner = box_recursive(
          box,
          header["contents"],
          header["layout"]
        );
        box = box_inner;
      }
    }
  }
  header_object = header_object.replace("<!-- inner -->", box);

  box = "";
  for (let key in body) {
    if (body.hasOwnProperty(key)) {
      if (key === "type" && body[key] === "box") {
        box = box_object(body);
        let box_inner = box_recursive(box, body["contents"], body["layout"]);
        box = box_inner;
      }
    }
  }
  body_object = body_object.replace("<!-- inner -->", box);

  box = "";
  for (let key in footer) {
    if (footer.hasOwnProperty(key)) {
      if (key === "type" && footer[key] === "box") {
        box = box_object(footer);
        let box_inner = box_recursive(
          box,
          footer["contents"],
          footer["layout"]
        );
        box = box_inner;
      }
    }
  }
  footer_object = footer_object.replace("<!-- inner -->", box);

  bubble = bubble.replace("<!-- hero -->", hero_object);
  bubble = bubble.replace("<!-- header -->", header_object);
  bubble = bubble.replace("<!-- body -->", body_object);
  bubble = bubble.replace("<!-- footer -->", footer_object);

  return bubble;
}
function box_recursive(parent_box: any, json: any, layout: any) {
  let result: any = [];
  json.forEach((obj: any, index: number) => {
    let temp;
    if (obj["type"] === "box") {
      let temp2 = box_object(obj);
      temp = box_recursive(temp2, obj["contents"], obj["layout"]);
    } else if (
      obj["type"] === "text" &&
      obj["contents"] &&
      obj["contents"].length > 0
    ) {
      let temp2 = convert_object(obj);
      temp = box_recursive(temp2, obj["contents"], obj["layout"]);
    } else {
      const objWithLayout = {
        ...obj,
        layout,
      };
      temp = convert_object(objWithLayout);
    }
    result[index] = temp;
  });

  json.forEach((obj: any, index: number) => {
    if (result[index]) {
      result[index] = result[index].replace("<!-- content -->", "");
      parent_box = parent_box.replace(
        "<!-- content -->",
        result[index] + "<!-- content -->"
      );
    }
  });

  return parent_box;
}

function convert_object(json: any) {
  let object;
  switch (json["type"]) {
    case "image":
      object = image_object(json);
      break;
    case "icon":
      object = icon_object(json);
      break;
    case "text":
      object = text_object(json);
      break;
    case "span":
      object = span_object(json);
      break;
    case "button":
      object = button_object(json);
      break;
    case "filler":
      object = filler_object(json);
      break;
    case "spacer":
      object = spacer_object(json);
      break;
    case "separator":
      object = separator_object(json);
      break;
    default:
      object = null;
  }
  return object;
}

function box_object(json: any) {
  let style = "";
  let {
    layout,
    position,
    flex,
    spacing,
    margin,
    width,
    height,
    backgroundColor,
    borderColor,
    borderWidth,
    cornerRadius,
    justifyContent,
    alignItems,
    offsetTop,
    offsetBottom,
    offsetStart,
    offsetEnd,
    paddingAll,
    paddingTop,
    paddingBottom,
    paddingStart,
    paddingEnd,
    background,
  } = json;

  let exmgn,
    layout1,
    layout2,
    fl,
    exabs,
    spc,
    ExBdr,
    ExBdrRad,
    ext,
    exb,
    exl,
    exr,
    ExPadA,
    ExPadT,
    ExPadB,
    ExPadL,
    ExPadR;
  if (layout === "baseline") {
    layout1 = "hr";
    layout2 = "bl";
  } else if (layout === "horizontal") {
    layout1 = "hr";
    layout2 = "";
  } else if (layout === "vertical") {
    layout1 = "vr";
    layout2 = "";
  }
  fl = "";

  if (flex > 3) {
    style += `-webkit-box-flex:${flex};flex-grow:${flex};`;
  } else if (flex >= 0) {
    fl = `fl${flex}`;
  } else if (width) {
    fl = `fl0`;
  } else {
    fl = "";
  }

  exabs = position === "absolute" ? "ExAbs" : "";

  if (spacing && spacing.indexOf("px") >= 0) {
    spc = "";
  } else {
    spc = spacing ? "spc" + upperalldigit(spacing) : "";
  }

  if (margin && margin.indexOf("px") >= 0) {
    style += `margin-top:${margin};`;
    exmgn = "";
  } else {
    exmgn = margin ? "ExMgnT" + upperalldigit(margin) : "";
  }
  if (width && width !== "") {
    style += `width:${width};`;
  }
  if (height && height !== "") {
    style += `height:${height};`;
  }
  if (backgroundColor) {
    style += `background-color:${backgroundColor} !important;`;
  }
  if (borderColor) {
    style += `border-color:${borderColor} !important;`;
  }

  if (borderWidth && borderWidth.indexOf("px") >= 0) {
    style += `border-width:${borderWidth};`;
    ExBdr = "";
  } else {
    switch (borderWidth) {
      case "none":
        ExBdr = "ExBdrWdtNone";
        break;
      case "light":
        ExBdr = "ExBdrWdtLgh";
        break;
      case "normal":
        ExBdr = "ExBdrWdtNml";
        break;
      case "medium":
        ExBdr = "ExBdrWdtMdm";
        break;
      case "semi-bold":
        ExBdr = "ExBdrWdtSbd";
        break;
      case "bold":
        ExBdr = "ExBdrWdtBld";
        break;
      default:
        ExBdr = "";
      // code block
    }
  }
  if (cornerRadius && cornerRadius.indexOf("px") >= 0) {
    style += `border-radius:${cornerRadius};`;
    ExBdrRad = "";
  } else {
    ExBdrRad = cornerRadius ? "ExBdrRad" + upperalldigit(cornerRadius) : "";
  }

  let jfc = "";
  if (justifyContent && justifyContent !== "") {
    switch (justifyContent) {
      case "center":
        jfc = "itms-jfcC";
        break;
      case "flex-start":
        jfc = "itms-jfcS";
        break;
      case "flex-end":
        jfc = "itms-jfcE";
        break;
      case "space-between":
        jfc = "itms-jfcSB";
        break;
      case "space-around":
        jfc = "itms-jfcSA";
        break;
      case "space-evenly":
        jfc = "itms-jfcSE";
        break;
      default:
        jfc = "";
      // code block
    }
  }
  let alg = "";
  if (alignItems && alignItems !== "") {
    switch (alignItems) {
      case "center":
        alg = "itms-algC";
        break;
      case "flex-start":
        alg = "itms-algS";
        break;
      case "flex-end":
        alg = "itms-algE";
        break;
      default:
        alg = "";
      // code block
    }
  }

  if (offsetTop && offsetTop.indexOf("px") >= 0) {
    style += `top:${offsetTop};`;
    ext = "";
  } else {
    ext = offsetTop ? "ExT" + upperalldigit(offsetTop) : "";
  }

  if (offsetBottom && offsetBottom.indexOf("px") >= 0) {
    style += `bottom:${offsetBottom};`;
    exb = "";
  } else {
    exb = offsetBottom ? "ExB" + upperalldigit(offsetBottom) : "";
  }

  if (offsetStart && offsetStart.indexOf("px") >= 0) {
    style += `left:${offsetStart};`;
    exl = "";
  } else {
    exl = offsetStart ? "ExL" + upperalldigit(offsetStart) : "";
  }

  if (offsetEnd && offsetEnd.indexOf("px") >= 0) {
    style += `right:${offsetEnd};`;
    exr = "";
  } else {
    exr = offsetEnd ? "ExR" + upperalldigit(offsetEnd) : "";
  }

  if (paddingAll && paddingAll.indexOf("px") >= 0) {
    style += `padding:${paddingAll};`;
    ExPadA = "";
  } else {
    ExPadA = paddingAll ? "ExPadA" + upperalldigit(paddingAll) : "";
  }

  if (paddingTop && paddingTop.indexOf("px") >= 0) {
    style += `padding-top:${paddingTop};`;
    ExPadT = "";
  } else {
    ExPadT = paddingTop ? "ExPadT" + upperalldigit(paddingTop) : "";
  }

  if (paddingBottom && paddingBottom.indexOf("px") >= 0) {
    style += `padding-bottom:${paddingBottom};`;
    ExPadB = "";
  } else {
    ExPadB = paddingBottom ? "ExPadB" + upperalldigit(paddingBottom) : "";
  }

  if (paddingStart && paddingStart.indexOf("px") >= 0) {
    style += `padding-left:${paddingStart};`;
    ExPadL = "";
  } else {
    ExPadL = paddingStart ? "ExPadL" + upperalldigit(paddingStart) : "";
  }

  if (paddingEnd && paddingEnd.indexOf("px") >= 0) {
    style += `padding-right:${paddingEnd};`;
    ExPadR = "";
  } else {
    ExPadR = paddingEnd ? "ExPadR" + upperalldigit(paddingEnd) : "";
  }

  if (background && background.type === "linearGradient") {
    let centerPosition = background.centerPosition
      ? background.centerPosition
      : "50%";
    if (background.centerColor) {
      style += `background: linear-gradient(${background.angle}, ${background.startColor} 0%, ${background.centerColor} ${centerPosition}, ${background.endColor} 100%);`;
    } else {
      style += `background: linear-gradient(${background.angle}, ${background.startColor} 0%, ${background.endColor} 100%);`;
    }
  }

  return `<div class="MdBx ${layout1} ${layout2} ${fl} ${exabs} ${exmgn} ${spc} ${ExBdr} ${ExBdrRad} ${jfc} ${alg} ${ext} ${exb} ${exl} ${exr} ${ExPadA} ${ExPadT} ${ExPadB} ${ExPadL} ${ExPadR}" style="${style}"><!-- content --></div>`;
}

function button_object(json: any) {
  let style2 = "";
  let style3 = "";

  let {
    flex,
    margin,
    position,
    height,
    style,
    color,
    gravity,

    adjustMode,
    offsetTop,
    offsetBottom,
    offsetStart,
    offsetEnd,
    action,
  } = json;

  let exmgn,
    fl = "",
    exabs,
    ext,
    exb,
    exl,
    exr,
    grv,
    ExBtn,
    ExBtnfc;

  if (flex > 3) {
    style2 += `-webkit-box-flex:${flex};flex-grow:${flex};`;
  } else {
    fl = flex >= 0 ? `fl${flex}` : "";
  }
  exabs = position === "absolute" ? "ExAbs" : "";

  if (margin && margin.indexOf("px") >= 0) {
    style2 += `margin-top:${margin};`;
    exmgn = "";
  } else {
    exmgn = margin ? "ExMgnT" + upperalldigit(margin) : "";
  }

  height =
    !height || height === "" || height === "md"
      ? ""
      : "Ex" + upperalldigit(height);
  grv =
    gravity === "bottom" || gravity === "center"
      ? "grv" + upper1digit(gravity)
      : "";

  ExBtn = "ExBtnL";
  if (style && style !== "") {
    switch (style) {
      case "link":
        ExBtnfc = "ExBtnL";
        break;
      case "primary":
        ExBtn = "ExBtn1";
        break;
      case "secondary":
        ExBtn = "ExBtn2";
        break;
      default:
        ExBtn = "ExBtnL";
      // code block
    }
  }

  if (color) {
    style3 += `background-color:${color} !important;`;
  }

  if (offsetTop && offsetTop.indexOf("px") >= 0) {
    style2 += `top:${offsetTop};`;
    ext = "";
  } else {
    ext = offsetTop ? "ExT" + upperalldigit(offsetTop) : "";
  }

  if (offsetBottom && offsetBottom.indexOf("px") >= 0) {
    style2 += `bottom:${offsetBottom};`;
    exb = "";
  } else {
    exb = offsetBottom ? "ExB" + upperalldigit(offsetBottom) : "";
  }

  if (offsetStart && offsetStart.indexOf("px") >= 0) {
    style2 += `left:${offsetStart};`;
    exl = "";
  } else {
    exl = offsetStart ? "ExL" + upperalldigit(offsetStart) : "";
  }

  if (offsetEnd && offsetEnd.indexOf("px") >= 0) {
    style2 += `right:${offsetEnd};`;
    exr = "";
  } else {
    exr = offsetEnd ? "ExR" + upperalldigit(offsetEnd) : "";
  }

  action = !action ? { type: "none" } : action;
  if (action.type === "uri") {
    return `<div class="MdBtn ${ExBtn} ${height} ${fl} ${exabs} ${exmgn} ${grv} ${ext} ${exb} ${exl} ${exr}" style="${style2}" id="8d1efea2-4017-4c89-8931-98a5f4f141f2"><a href="${action.uri}" target="_blank" style="${style3}"><div>${action.label}</div></a></div>`;
  } else if (action.type === "message") {
    return `<div class="MdBtn ${ExBtn} ${height} ${fl} ${exabs} ${exmgn} ${grv} ${ext} ${exb} ${exl} ${exr}" style="${style2}" id="8d1efea2-4017-4c89-8931-98a5f4f141f2"><a  style="${style3}"><div>${action.label}</div></a></div>`;
  } else if (action.type === "postback") {
    return `<div class="MdBtn ${ExBtn} ${height} ${fl} ${exabs} ${exmgn} ${grv} ${ext} ${exb} ${exl} ${exr}" style="${style2}" id="8d1efea2-4017-4c89-8931-98a5f4f141f2"><a  style="${style3}"><div>${action.label}</div></a></div>`;
  } else {
    return `<div class="MdBtn ${ExBtn} ${height} ${fl} ${exabs} ${exmgn} ${grv} ${ext} ${exb} ${exl} ${exr}" style="${style2}" id="8d1efea2-4017-4c89-8931-98a5f4f141f2"><a style="${style3}"><div>${action.label}</div></a></div>`;
  }
}
function filler_object(json: any) {
  let style = "";
  let { flex } = json;
  let fl = "";
  if (flex > 3) {
    style += `-webkit-box-flex:${flex};flex-grow:${flex};`;
  } else {
    fl = flex >= 0 ? `fl${flex}` : "";
  }
  return `<div class="mdBxFiller ${fl}" style="${style}" ></div>`;
}
function icon_object(json: any) {
  let style2 = "";
  let {
    size,
    aspectRatio,
    url,
    position,
    margin,
    offsetTop,
    offsetBottom,
    offsetStart,
    offsetEnd,
  } = json;
  let styleimg = `background-image:url('${url}');`;
  let ratio = 0;
  let exl, exabs, exmgn, ext, exb, exr;
  size = !size || size === "" ? "md" : size;
  if (size.indexOf("px") >= 0) {
    style2 += `font-size:${size};`;
    size = "";
  } else {
    size = "Ex" + upperalldigit(size);
  }

  if (!aspectRatio || aspectRatio === "") {
    styleimg += `width:1em;`;
  } else {
    //@ts-ignore
    ratio = ratio[0] / ratio[1];
    styleimg += `width:${ratio}em;`;
  }
  exabs = position === "absolute" ? "ExAbs" : "";

  if (margin && margin.indexOf("px") >= 0) {
    style2 += `margin-top:${margin};`;
    exmgn = "";
  } else {
    exmgn = margin ? "ExMgnT" + upperalldigit(margin) : "";
  }

  if (offsetTop && offsetTop.indexOf("px") >= 0) {
    style2 += `top:${offsetTop};`;
    ext = "";
  } else {
    ext = offsetTop ? "ExT" + upperalldigit(offsetTop) : "";
  }

  if (offsetBottom && offsetBottom.indexOf("px") >= 0) {
    style2 += `bottom:${offsetBottom};`;
    exb = "";
  } else {
    exb = offsetBottom ? "ExB" + upperalldigit(offsetBottom) : "";
  }

  if (offsetStart && offsetStart.indexOf("px") >= 0) {
    style2 += `left:${offsetStart};`;
    exl = "";
  } else {
    exl = offsetStart ? "ExL" + upperalldigit(offsetStart) : "";
  }

  if (offsetEnd && offsetEnd.indexOf("px") >= 0) {
    style2 += `right:${offsetEnd};`;
    exr = "";
  } else {
    exr = offsetEnd ? "ExR" + upperalldigit(offsetEnd) : "";
  }

  return `<div class="MdIco fl0 ${size} ${exabs} ${exmgn} ${ext} ${exb} ${exl} ${exr}" style="${style2}" ><div><span style="${styleimg}"></span></div></div>`;
}
function image_object(json: any) {
  let style = "";
  let style2 = "";
  let {
    aspectMode,
    size,
    aspectRatio,
    url,
    position,
    flex,
    margin,
    align,
    gravity,
    backgroundColor,
    offsetTop,
    offsetBottom,
    offsetStart,
    offsetEnd,
    action,
  } = json;
  let styleimg = `background-image:url('${url}');`;
  let ratio = 0;
  let exl, exr;
  if (backgroundColor) {
    styleimg += `background-color:${backgroundColor} !important;`;
  }

  aspectMode = !aspectMode || aspectMode === "" ? "fit" : aspectMode;
  size = !size || size === "" ? "md" : size;
  aspectMode = upperalldigit(aspectMode);
  if (size.indexOf("px") >= 0) {
    style2 += `width:${size};`;
    size = "";
  } else {
    size = "Ex" + upperalldigit(size);
  }

  if (!aspectRatio || aspectRatio === "") {
    //@ts-ignore
    ratio = "100";
  } else {
    ratio = aspectRatio.split(":");
    //@ts-ignore
    ratio = (ratio[1] * 100) / ratio[0];
  }
  let fl = "";
  if (flex > 3) {
    style += `-webkit-box-flex:${flex};flex-grow:${flex};`;
  } else {
    fl = flex >= 0 ? `fl${flex}` : "";
  }
  let exabs = position === "absolute" ? "ExAbs" : "";
  let exmgn, ext, exb;

  if (margin && margin.indexOf("px") >= 0) {
    style += `margin-top:${margin};`;
    exmgn = "";
  } else {
    exmgn = margin ? "ExMgnT" + upperalldigit(margin) : "";
  }

  let alg =
    align === "start" || align === "end" ? "alg" + upper1digit(align) : "";
  let grv =
    gravity === "bottom" || gravity === "center"
      ? "grv" + upper1digit(gravity)
      : "";

  if (offsetTop && offsetTop.indexOf("px") >= 0) {
    style += `top:${offsetTop};`;
    ext = "";
  } else {
    ext = offsetTop ? "ExT" + upperalldigit(offsetTop) : "";
  }

  if (offsetBottom && offsetBottom.indexOf("px") >= 0) {
    style += `bottom:${offsetBottom};`;
    exb = "";
  } else {
    exb = offsetBottom ? "ExB" + upperalldigit(offsetBottom) : "";
  }

  if (offsetStart && offsetStart.indexOf("px") >= 0) {
    style += `left:${offsetStart};`;
    exl = "";
  } else {
    exl = offsetStart ? "ExL" + upperalldigit(offsetStart) : "";
  }

  if (offsetEnd && offsetEnd.indexOf("px") >= 0) {
    style += `right:${offsetEnd};`;
    exr = "";
  } else {
    exr = offsetEnd ? "ExR" + upperalldigit(offsetEnd) : "";
  }
  action = !action ? { type: "none" } : action;
  if (action.type === "uri") {
    return `<div class="MdImg Ex${aspectMode} ${fl} ${size} ${exabs} ${exmgn} ${alg} ${grv} ${ext} ${exb} ${exl} ${exr}"  style="${style}">
                   <div style="${style2}">
                      <a href="${action.uri}" target="_blank" style="padding-bottom:${ratio}%;">
                         <span style="${styleimg}"></span>
                      </a>
                   </div>
                </div>`;
  } else if (action.type === "message") {
    return `<div class="MdImg Ex${aspectMode} ${fl} ${size} ${exabs} ${exmgn} ${alg} ${grv} ${ext} ${exb} ${exl} ${exr}"  style="${style}">
                   <div style="${style2}">
                      <a onclick="alert('message: ${action.text}')" style="padding-bottom:${ratio}%;">
                         <span style="${styleimg}"></span>
                      </a>
                   </div>
                </div>`;
  } else if (action.type === "postback") {
    return `<div class="MdImg Ex${aspectMode} ${fl} ${size} ${exabs} ${exmgn} ${alg} ${grv} ${ext} ${exb} ${exl} ${exr}"  style="${style}">
                   <div style="${style2}">
                      <a onclick="alert('postback data: ${action.data}')" style="padding-bottom:${ratio}%;">
                         <span style="${styleimg}"></span>
                      </a>
                   </div>
                </div>`;
  } else {
    return `<div class="MdImg Ex${aspectMode} ${fl} ${size} ${exabs} ${exmgn} ${alg} ${grv} ${ext} ${exb} ${exl} ${exr}"  style="${style}">
                   <div style="${style2}">
                      <a style="padding-bottom:${ratio}%;">
                         <span style="${styleimg}"></span>
                      </a>
                   </div>
                </div>`;
  }
}
function separator_object(json: any) {
  let style = "";
  let { margin, color } = json;
  let exmgn;
  if (margin && margin.indexOf("px") >= 0) {
    style += `margin-top:${margin};`;
    exmgn = "";
  } else {
    exmgn = margin ? "ExMgnT" + upperalldigit(margin) : "";
  }
  if (color) {
    style += `border-color:${color} !important;`;
  }

  return `<div class="fl0 MdSep ${exmgn}" style="${style}" ></div>`;
}
function spacer_object(json: any) {
  let { size } = json;
  size = !size || size === "" ? "md" : size;
  if (size.indexOf("px") >= 0) {
    size = "";
  } else {
    size = "spc" + upperalldigit(size);
  }
  return `<div class="mdBxSpacer ${size} fl0" ></div>`;
}
function span_object(json: any) {
  let style2 = "";
  let { text, size, color, weight, style, decoration } = json;

  if (size && size !== "") {
    if (size.indexOf("px") >= 0) {
      style2 += `font-size:${size};`;
      size = "";
    } else {
      size = "Ex" + upperalldigit(size);
    }
  } else {
    size = "";
  }

  if (color && color !== "") {
    style2 += `color:${color};`;
  }
  let ExWB = weight === "bold" ? "ExWB" : "";
  let ExFntSty =
    style === "normal" ? "ExFntStyNml" : style === "italic" ? "ExFntStyIt" : "";
  let ExTxtDec =
    decoration === "line-through"
      ? "ExTxtDecLt"
      : decoration === "underline"
      ? "ExTxtDecUl"
      : decoration === "none"
      ? "ExTxtDecNone"
      : "";

  return `<span class="MdSpn ${ExWB} ${size} ${ExFntSty} ${ExTxtDec}" style="${style2}" >${text}</span>`;
}
function carousel_struc() {
  return `<div class="LySlider"><div class="lyInner"><!-- inner --></div></div>`;
}

function bubble_struc(json: any) {
  let { size, direction, action } = json;
  size = !size || size === "" ? "medium" : size;
  direction = !direction || direction == "" ? "ltr" : direction;
  size = upper2digit(size);

  return `<div class="lyItem Ly${size}"><div class="T1 fx${direction.toUpperCase()}" dir="${direction}"><!-- hero --><!-- header --><!-- body --><!-- footer --></div></div>`;
}
function hero_struc() {
  return `<div class="t1Hero"><!-- inner --></div>`;
}
function header_struc() {
  return `<div class="t1Header"><!-- inner --></div>`;
}
function body_struc(json: any) {
  let { footer } = json;
  let ExHasFooter = json ? "ExHasFooter" : "";
  return `<div class="t1Body ${ExHasFooter}"><!-- inner --></div>`;
}
function footer_struc() {
  return `<div class="t1Footer"><!-- inner --></div>`;
}
function text_object(json: any) {
  let style2 = "";
  let {
    flex,
    margin,
    size,
    position,
    align,
    gravity,
    text,
    color,
    weight,
    style,
    decoration,
    wrap,

    maxLines,

    adjustMode,
    offsetTop,
    offsetBottom,
    offsetStart,
    offsetEnd,
    layout,
  } = json;
  let exabs,
    exmgn,
    alg,
    grv,
    ExWB,
    ExFntSty,
    ExTxtDec,
    ExWrap,
    ext,
    exb,
    exl,
    exr;
  let fl = "";
  if (flex > 3) {
    style2 += `-webkit-box-flex:${flex};flex-grow:${flex};`;
  } else {
    fl = flex >= 0 ? `fl${flex}` : "";
  }
  exabs = position === "absolute" ? "ExAbs" : "";

  if (layout === "horizontal") {
    if (margin && margin.indexOf("px") >= 0) {
      style2 += `margin-left:${margin};`;
      exmgn = "";
    } else {
      exmgn = margin ? "ExMgnL" + upperalldigit(margin) : "";
    }
  } else if (layout === "vertical") {
    if (margin && margin.indexOf("px") >= 0) {
      style2 += `margin-top:${margin};`;
      exmgn = "";
    } else {
      exmgn = margin ? "ExMgnT" + upperalldigit(margin) : "";
    }
  }

  alg =
    align === "start" || align === "end" || align === "center"
      ? "ExAlg" + upper1digit(align)
      : "";
  grv =
    gravity === "bottom" || gravity === "center"
      ? "grv" + upper1digit(gravity)
      : "";
  size = !size || size === "" ? "md" : size;
  if (size.indexOf("px") >= 0) {
    style2 += `font-size:${size};`;
    size = "";
  } else {
    size = "Ex" + upperalldigit(size);
  }

  if (color && color !== "") {
    style2 += `color:${color};`;
  }
  ExWB = weight === "bold" ? "ExWB" : "";
  ExFntSty =
    style === "normal" ? "ExFntStyNml" : style === "italic" ? "ExFntStyIt" : "";
  ExTxtDec =
    decoration === "line-through"
      ? "ExTxtDecLt"
      : decoration === "underline"
      ? "ExTxtDecUl"
      : decoration === "none"
      ? "ExTxtDecNone"
      : "";
  ExWrap = wrap === true ? "ExWrap" : "";
  if (offsetTop && offsetTop.indexOf("px") >= 0) {
    style2 += `top:${offsetTop};`;
    ext = "";
  } else {
    ext = offsetTop ? "ExT" + upperalldigit(offsetTop) : "";
  }

  if (offsetBottom && offsetBottom.indexOf("px") >= 0) {
    style2 += `bottom:${offsetBottom};`;
    exb = "";
  } else {
    exb = offsetBottom ? "ExB" + upperalldigit(offsetBottom) : "";
  }

  if (offsetStart && offsetStart.indexOf("px") >= 0) {
    style2 += `left:${offsetStart};`;
    exl = "";
  } else {
    exl = offsetStart ? "ExL" + upperalldigit(offsetStart) : "";
  }

  if (offsetEnd && offsetEnd.indexOf("px") >= 0) {
    style2 += `right:${offsetEnd};`;
    exr = "";
  } else {
    exr = offsetEnd ? "ExR" + upperalldigit(offsetEnd) : "";
  }
  text = !text ? "" : text;
  return `<div class="MdTxt ${fl} ${exabs} ${exmgn} ${alg} ${grv} ${size} ${ExWB} ${ExFntSty} ${ExTxtDec} ${ExWrap} ${ext} ${exb} ${exl} ${exr}" style="${style2}"><p>${text}<!-- content --></p></div>`;
}
function upper1digit(str: string) {
  return str.charAt(0).toUpperCase();
}
function upper2digit(str: string) {
  return str.charAt(0).toUpperCase() + str.substring(1, 2);
}
function upperalldigit(str: string) {
  if (str.length === 3) {
    return (
      str.charAt(0).toUpperCase() + str.charAt(1).toUpperCase() + str.slice(2)
    );
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}
