import React from "react";

import {
  EMessageActionType,
  IImageMap,
  IImagemapAction,
  IMessageAction,
} from "@types";
import { createDataAttr } from "@utils";
type ImageMapMessageProps = {
  message: IImageMap;
  onSendAction: (action: IMessageAction) => void;
};

export const ImageMapMessage = ({
  message,
  onSendAction,
}: ImageMapMessageProps) => {
  const _renderActions = (actions: IImagemapAction[]) => {
    return actions.map((action) => {
      if (
        action.type === EMessageActionType.DATE_TIME_PICKER ||
        action.type === EMessageActionType.CAMERA_ACTION
      ) {
        return null;
      }
      // link action
      if (action.type === EMessageActionType.URI_ACTION) {
        return (
          <a
            key={action.id}
            className="absolute"
            href={action.uri}
            target="_blank"
            style={{
              top: `${action.area.y}%`,
              left: `${action.area.x}%`,
              width: `${action.area.width}%`,
              height: `${action.area.height}%`,
            }}
            {...createDataAttr("imagemap-button")}
          />
        );
      }
      // call action
      if (action.type === EMessageActionType.CALL_ACTION) {
        return (
          <a
            key={action.id}
            className="absolute"
            href={`tel:${action.phoneNo}`}
            style={{
              top: `${action.area.y}%`,
              left: `${action.area.x}%`,
              width: `${action.area.width}%`,
              height: `${action.area.height}%`,
            }}
            {...createDataAttr("imagemap-button")}
          />
        );
      }

      return (
        <button
          key={action.id}
          onClick={() => {
            const { area, ...rest } = action;
            onSendAction(rest);
          }}
          className="absolute"
          style={{
            top: `${action.area.y}%`,
            left: `${action.area.x}%`,
            width: `${action.area.width}%`,
            height: `${action.area.height}%`,
          }}
          {...createDataAttr("imagemap-button")}
        />
      );
    });
  };
  return (
    <div className="relative w-fit" {...createDataAttr("imagemap-message")}>
      {_renderActions(message.actions)}
      <img
        loading="lazy"
        alt={message.altText}
        className="h-[400px] max-h-[500px]  rounded-[10px] object-cover"
        src={message.imgUrl}
      />
    </div>
  );
};
