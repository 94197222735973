import { DatetimePickerAction, MessageAction, URIAction } from "@line/bot-sdk";

export const enum EMessageActionType {
  URI_ACTION = "uri",
  CALL_ACTION = "call_action",
  TEMPLATE_ACTION = "template_action",
  CAMERA_ACTION = "camera",
  MESSAGE_ACTION = "message",
  FLOW_ACTION = "flow_action",
  DATE_TIME_PICKER = "datetimepicker",
}

export type IUriAction = URIAction;

export type ICallAction = {
  type: "call_action";
  id: string;
  label?: string;
  phoneNo: string;
};

export type ITemplateAction = {
  type: "template_action";
  label?: string;
  templateName: string;
};

export type IFlowAction = {
  type: "flow_action";
  label?: string;
  action: {
    id: string;
    name: string;
  };
  flowEngine: {
    flowEngineId: string;
    flowEngineEndpoint: string;
  };
};

export type IMessageAction = {
  id: string;
  label: string;
} & (
  | MessageAction
  | DatetimePickerAction
  | IUriAction
  | ICallAction
  | ITemplateAction
  | IFlowAction
  | {
      type: "camera";
    }
);
