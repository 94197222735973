import React from "react";
import { createDataAttr } from "@utils";
import { LinkItUrl } from "react-linkify-it";

export type ITextMessageProps = {
  message?: string;
};

export const TextMessage = ({
  message,
}: React.PropsWithChildren<ITextMessageProps>) => {
  return (
    <LinkItUrl {...createDataAttr("text-message")} className="text-blue-700">
      <p
        style={{
          wordBreak: "break-word",
        }}
        className="text-message group 
        inline-block w-fit 
        whitespace-pre-wrap 
        break-words rounded-[15px] bg-white px-[12px] py-[10px] text-black"
      >
        {message}
      </p>
    </LinkItUrl>
  );
};
