import { useEffect, useRef } from "react";
import { useChatContext } from "..";
import { useActor, useSelector } from "@xstate/react";
import { IChatMachineBoxContext } from "../machine";
import { Livechat } from "@api";
import { IInCommingMessage, IMessageAction } from "@types";
import { useAppStore } from "@store/appStore";
import { shallow } from "zustand/shallow";
import { generateActionMessage } from "@fns";
import { BOTTOM_SCROLL_THRESHOLD } from "@constants";

const selectMessage = ({ context }: { context: IChatMachineBoxContext }) =>
  context.messages;
const selectFirstItemIndex = ({
  context,
}: {
  context: IChatMachineBoxContext;
}) => context.firstItemIndex;

export const useMessageBox = () => {
  const wrapRef = useRef<HTMLDivElement>(null);
  const machineService = useChatContext();
  const { user, token, room } = useAppStore((state) => {
    return {
      user: state.user,
      token: state.token,
      room: state.room,
    };
  }, shallow);
  const [state] = useActor(machineService.chatService);
  const { send } = machineService.chatService;
  const messages = useSelector(machineService.chatService, selectMessage);
  const firstItemIndex = useSelector(
    machineService.chatService,
    selectFirstItemIndex
  );

  useEffect(() => {
    if (!wrapRef.current) return;
    const isFirstFetch = state.matches("firstFetchMessageSuccess");
    const isSendingMessage = state.matches("sendingMessage");
    const isUploadingMessage = state.matches("uploadingMessage");
    const isNewIncomingMessage = state.matches("newIncomingMessage");
    const { scrollTop, scrollHeight, clientHeight } = wrapRef.current;
    if (isFirstFetch || isSendingMessage || isUploadingMessage) {
      // Use requestAnimationFrame to wait for the next animation frame
      requestAnimationFrame(() => {
        if (!wrapRef.current) return;
        // Scroll to the bottom
        wrapRef.current.scrollTop = scrollHeight;
      });
    }
    // Calculate the difference between total scroll height and current scroll position
    const scrollDifference = scrollHeight - (scrollTop + clientHeight);
    // new incoming message and scroll around bottom
    if (isNewIncomingMessage && scrollDifference <= BOTTOM_SCROLL_THRESHOLD) {
      requestAnimationFrame(() => {
        if (!wrapRef.current) return;
        // Scroll to the bottom
        wrapRef.current.scrollTop = scrollHeight;
      });
    }
  }, [state, wrapRef]);

  useEffect(() => {
    let ignore = false;
    Livechat.onMessage((message) => {
      const incomming = message as unknown as IInCommingMessage;
      if (!ignore) {
        send("NEW_INCOMING_MESSAGE", {
          payload: {
            uid: user._id,
            newMessage: incomming,
          },
        });
      }
    });
  }, []);

  return {
    wrapRef,
    firstItemIndex,
    isFetchFailed: state.matches("fetchMoreMessagesFailed"),
    isFirstFetching: state.matches("firstFetchingMessages"),
    isUserSendMessage: state.matches("sendingMessage"),
    isLoadingMore: state.matches("fetchingMoreMessages"),
    messages,
    hasMore: state.context.hasMore,
    onSendAction: async (action: IMessageAction) => {
      try {
        const outgoing = await generateActionMessage(action, room, token);
        // if null
        if (!outgoing) return;
        send("SEND_MESSAGE", outgoing);
      } catch (error) {
        console.error("error create message action ", error);
      }
    },
    onLoadMoreMessages: () => {
      send("LOAD_MORE_MESSAGES");
    },
  };
};
