import React from "react";
import { useComposer } from "./useComposer";
import { EComposerMode } from "@constants/composer";
import { TextComposer } from "./TextComposer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faKeyboard } from "@fortawesome/pro-regular-svg-icons";
import { RichmenuComposer } from "./RichmenuComposer";
export type IComposerProps = {
  richmenuState: {
    isLoading: boolean;
    isError: boolean;
  };
};

export const Composer = React.memo(({ richmenuState }: IComposerProps) => {
  const {
    acceptFileTypes,
    richmenu,
    mode,
    textAreaRef,
    fileInputRef,
    message,
    onType,
    onSend,
    onInputFileChange,
    onChangeMode,
  } = useComposer();

  const _renderToggleMenu = () => {
    if (richmenuState.isLoading || richmenuState.isError) {
      return null;
    }
    return (
      <>
        {mode === EComposerMode.CHAT ? (
          <FontAwesomeIcon
            icon={faBars}
            className="mr-[16px] cursor-pointer text-[20px]"
            onClick={onChangeMode}
          />
        ) : (
          <FontAwesomeIcon
            icon={faKeyboard}
            className="cursor-pointer text-[20px]"
            onClick={onChangeMode}
          />
        )}
      </>
    );
  };

  return (
    <footer className="relative flex items-center bg-white py-[6px] pl-[20px]">
      {_renderToggleMenu()}
      {mode === EComposerMode.CHAT ? (
        <TextComposer
          acceptFileTypes={acceptFileTypes ?? []}
          textAreaRef={textAreaRef}
          fileInputRef={fileInputRef}
          message={message}
          onType={onType}
          onSend={onSend}
          onInputFileChange={onInputFileChange}
        />
      ) : (
        <RichmenuComposer name={richmenu?.chatBarText ?? ""} />
      )}
    </footer>
  );
});
