import { otgMsgGenerator } from "@fns";
import {
  EMessageActionType,
  EMessageType,
  IMessageAction,
  IRichmenuAction,
  IRoom,
} from "@types";

export const generateActionMessage = async (
  action: IMessageAction | IRichmenuAction,
  room: IRoom,
  token: string
) => {
  if (action.type === EMessageActionType.MESSAGE_ACTION) {
    const outgoing = await otgMsgGenerator({
      type: EMessageType.TEXT,
      msg: action.text,
      rid: room._id,
      token: token,
    });
    return outgoing;
  }

  if (action.type === EMessageActionType.TEMPLATE_ACTION) {
    const outgoing = await otgMsgGenerator({
      type: EMessageType.POSTBACK,
      msg: action.templateName,
      displayMsg: action.label || action.templateName,
      rid: room._id,
      token: token,
    });
    return outgoing;
  }

  if (action.type === EMessageActionType.FLOW_ACTION) {
    const { action: flowAction, flowEngine } = action;
    const actionStr = `@${flowEngine.flowEngineId}/${flowAction.name.slice(1)}`;
    const outgoing = await otgMsgGenerator({
      type: EMessageType.FLOW_POSTBACK,
      msg: actionStr,
      displayMsg: action.label || "",
      rid: room._id,
      token: token,
    });
    return outgoing;
  }

  return null;
};
