import React, { useCallback, useMemo } from "react";
import { RICHMENU_DEFAULT_DIVIND } from "@constants";
import { EMessageActionType, IRichmenu, IRichmenuBoundAction } from "@types";
import { useRichmenuStore } from "@store/richmenuStore";
import { shallow } from "zustand/shallow";
import { useAppStore } from "@store/appStore";
import { useRichmenu } from "./useRichmenu";

export const RichMenu = () => {
  const { richmenu, isOpenRichmenu } = useRichmenuStore((state) => {
    return {
      richmenu: state.richmenu,
      isOpenRichmenu: state.isOpenRichmenu,
    };
  }, shallow);
  const { richmenuSetting } = useAppStore((state) => {
    return {
      richmenuSetting: state.settings.richmenuSetting,
    };
  }, shallow);
  const { onSendRichmenuAction } = useRichmenu();
  const _renderActions = useCallback((actions: IRichmenuBoundAction[]) => {
    return actions.map(({ action, bounds }) => {
      if (action.type === EMessageActionType.URI_ACTION) {
        return (
          <a
            key={action.id}
            className="absolute"
            href={action.uri}
            target="_blank"
            style={{
              top: `${bounds.y}%`,
              left: `${bounds.x}%`,
              width: `${bounds.width}%`,
              height: `${bounds.height}%`,
            }}
          />
        );
      }

      if (action.type === EMessageActionType.CALL_ACTION) {
        return (
          <a
            key={action.id}
            className="absolute"
            href={`tel:${action.phoneNo}`}
            style={{
              top: `${bounds.y}%`,
              left: `${bounds.x}%`,
              width: `${bounds.width}%`,
              height: `${bounds.height}%`,
            }}
          />
        );
      }

      if (
        action.type === EMessageActionType.MESSAGE_ACTION ||
        action.type === EMessageActionType.TEMPLATE_ACTION ||
        action.type === EMessageActionType.FLOW_ACTION
      ) {
        return (
          <button
            key={action.id}
            onClick={() => {
              onSendRichmenuAction(action);
            }}
            className="absolute"
            style={{
              top: `${bounds.y}%`,
              left: `${bounds.x}%`,
              width: `${bounds.width}%`,
              height: `${bounds.height}%`,
            }}
          />
        );
      }
      return null;
    });
  }, []);
  const richmenuHeight = useMemo(() => {
    // fixed height
    if (richmenuSetting?.fixedHeight) {
      return richmenuSetting.fixedHeight;
    }
    // dividedBy
    if (richmenuSetting?.dividedBy) {
      return (
        window.innerWidth / RICHMENU_DEFAULT_DIVIND / richmenuSetting.dividedBy
      );
    }
    return window.innerWidth / RICHMENU_DEFAULT_DIVIND;
  }, [window.innerWidth]);
  return (
    <div
      style={{
        height: !isOpenRichmenu ? 0 : richmenuHeight,
      }}
      className={`relative w-full overflow-hidden transition-all duration-200 ease-in-out`}
    >
      {richmenu ? _renderActions(richmenu.areas) : null}
      <img
        style={{
          height: richmenuHeight,
        }}
        className={`w-full`}
        src={richmenu?.image ?? ""}
      />
    </div>
  );
};
