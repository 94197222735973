import { useState, useEffect } from "react";
import { useIframeMessage } from "@hooks/useIframeMessage";
import { useAppStore } from "@store/appStore";
import { shallow } from "zustand/shallow";
import { ISdkIframePostMessage, EMode, ISdkIframeSettings } from "sdk";
import { FAILED_INIT, NOT_IN_IFRAME } from "@constants/wording";
import {
  createCssFromLink,
  genrateToken,
  grantUser,
  initConnection,
  setRocketToken,
  subscribeRoom,
} from "@utils";
import { Livechat } from "@api";

export const useApp = () => {
  const [isInit, setIsInit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const { token, setSettings, setUser, setRoom, setToken, clearStorage } =
    useAppStore(
      (state) => ({
        token: state.token,
        user: state.user,
        room: state.room,
        setSettings: state.setSettings,
        setUser: state.setUser,
        setRoom: state.setRoom,
        setToken: state.setToken,
        clearStorage: state.clearStorage,
      }),
      shallow
    );

  useIframeMessage({
    listener: (data) => initApp(data),
  });

  useEffect(() => {
    // intialize app for standalone mode in case you run only client
    const inIframe = isInIframe();
    if (!inIframe && import.meta.env.MODE !== "standalone") {
      setLoading(false);
      setErrorMessage(NOT_IN_IFRAME);
      return;
    }
    if (import.meta.env.MODE === "standalone") {
      // if you want to add setting you can add here
      // this will use full screen mode by default if you run yarn dev:client:sta
      initApp({
        topic: "init",
        data: {
          channelId: import.meta.env.VITE_CHANNEL_ID,
          mode: EMode.FULL_SCREEN,
          richmenuSetting: {
            fixedHeight: 200, //px if add this divided by will not effect
            dividedBy: 3, // formular of this is window.innerWidth (100% width of iframe) / 1.666666666666666666666666666 / dividedBy
          },
        },
        sender: "ams_sdk",
      });
    }

    return () => {
      Livechat.disconnect();
    };
  }, []);

  const isInIframe = () => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  };

  const initApp = async (data: ISdkIframePostMessage) => {
    try {
      const { topic, data: settings } = data;
      if (topic === "init") {
        createCssFromLink(settings.cssLinks);
        await initialize(settings);
      }
      if (topic === "re-init") {
        setIsInit(false);
        clearStorage();
        await initialize(settings);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const initialize = async (settings: ISdkIframeSettings) => {
    try {
      setSettings({
        ...settings,
      });
      // token in local storage
      const newToken = genrateToken(token, settings.token);
      if (!newToken) throw new Error("Token is not set");
      setToken(newToken);

      // set token
      const isSetToken = setRocketToken(newToken);
      if (!isSetToken) {
        throw new Error("User is not set");
      }
      await initConnection();
      const user = await grantUser(newToken, {
        channelId: settings.channelId,
        displayName: settings.displayName,
        imageUrl: settings.imageUrl,
      });
      const room = await subscribeRoom();
      setUser(user);
      setRoom(room);
      setLoading(false);
      setIsInit(true);
    } catch (err) {
      setLoading(false);
      setErrorMessage(FAILED_INIT);
      throw err;
    }
  };

  return {
    errorMessage,
    isInit,
    token,
    loading,
  };
};
