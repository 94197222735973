import { BASE_URL } from "@configs/api";
import axios from "axios";
import { Livechat as LivechatClient } from "@ams/rocket-sdk";

export const client = axios.create({
  baseURL: BASE_URL,
});

export const Livechat = new LivechatClient({
  host: BASE_URL,
  protocol: "ddp",
  useSsl: true,
});
