import { DDPDriver } from "@ams/rocket-sdk/src/lib/drivers/ddp";
import { Livechat } from "@api";

export const initConnection = async () => {
  await Livechat.connect({});
  const driver = (await Livechat.socket) as DDPDriver;
  driver.ddp.onEvent("close", async (data) => {
    await Livechat.checkAndReopen();
  });
  return driver;
};
