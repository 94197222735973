import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { inspect } from "@xstate/inspect";
import { setup } from "@setup";
if (import.meta.env.DEV) {
  inspect({
    iframe: false,
  });
}

setup();
ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <App />
);
